/* eslint-disable @typescript-eslint/no-explicit-any */
// interfaces
import { TreeNode } from "../WhereSelect/helpers";

const getSelectedValues = (cityOptionsList: TreeNode[], cityIn: string | number[], singleValue = false) => {

  if (singleValue) {

    return cityOptionsList.filter(item => {
      if (Array.isArray(cityIn)) {
        return (cityIn.map(Number) as number[]).includes(item.value as number);
      }

      return +cityIn === item.value;
    });
  }

  return cityOptionsList.filter(item => (item.value as number[]).every(id => {
    if (Array.isArray(cityIn)) {
      return cityIn.map(Number).includes(id);
    }

    return +cityIn === id;
  }));
};


export function getNestedWhereSelectorValues(
  cityOptionsList: TreeNode[],
  cityIn: string | number[],
  selected = []
): TreeNode[] {

  if (!cityOptionsList.length) {
    return [];
  }

  if (cityOptionsList[0] && !cityOptionsList[0].children) {
    return selected.concat(getSelectedValues(cityOptionsList, cityIn, true) as any);
  }

  selected = selected.concat(getSelectedValues(cityOptionsList, cityIn) as any);

  const nested = cityOptionsList.map(city => city.children);
  const flattenNested = nested.flat() as TreeNode[];

  return getNestedWhereSelectorValues(flattenNested, cityIn, selected);
}

