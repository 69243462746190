import React from "react";

// interface
import { IIconComponentProps } from "@/types/components/IconComponentProps";

function ShopIcon(props: IIconComponentProps<SVGSVGElement>) {

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" { ...props }>
      <path d="M3.51676 9.07495V17.4083" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.4836 9.07495V17.4083" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.8518 17.4075V13.7042C11.8518 12.6817 11.0226 11.8525 10.0001 11.8525C8.9776 11.8525 8.14844 12.6817 8.14844 13.7042V17.4075" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.6665 17.4084H18.3332" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.58415 9.0742C2.16915 9.0642 1.24665 7.66336 1.85998 6.45586L3.28915 3.6417C3.61498 3.00003 4.29998 2.59253 5.05165 2.59253H14.9483C15.7 2.59253 16.385 3.00003 16.7108 3.6417L18.1408 6.45586C18.7541 7.66336 17.8316 9.0642 16.4166 9.0742C15.2358 9.0742 14.2783 8.26753 14.2783 7.2717V7.2317C14.2783 8.2492 13.3208 9.0742 12.14 9.0742C10.9591 9.0742 10.0016 8.2492 10.0016 7.2317C10.0016 8.2492 9.04415 9.0742 7.86331 9.0742C6.68248 9.0742 5.72498 8.2492 5.72498 7.2317V7.2717C5.72248 8.26753 4.76498 9.0742 3.58415 9.0742Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default ShopIcon;
