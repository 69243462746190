/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useCallback, useEffect, useRef, useState } from "react";
import uniqBy from "lodash/uniqBy";
import { IoClose } from "react-icons/io5";
import { FaChevronLeft } from "react-icons/fa";
import AnimateHeight from "react-animate-height";

// components
import Tag from "@/shared/Tag";
import WhereSelectItem from "./components/WhereSelectItem";

// helpers
import {
  areEqual,
  flattenChildrenRecursive,
  flattenLocationArray,
  removeChildrenFromFlattenLocations,
  selectLocationsByChildren,
  unselectLocationsByChildren,
  formatRegions
} from "./helpers";
import countryStateCity from "@/api/query/countryStateCity";
import { useDimension } from "@/hooks/useDimensions";

// assets
import styles from "@/assets/styles/components/where-select.module.scss";

const getDefaultHeight = (data: unknown[]) => data?.length * 46;

const WhereSelect = ({ value, onChange, overlayClassName }: any) => {
  const [maxHeight, setMaxHeight] = useState({ current: 50, next: null });
  const [selected, setSelected] = useState(null);
  const [nodesToShow, setNodesToShow] = useState([]);
  const [filterVal, setFilterVal] = useState("");

  const multiRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef(null);

  const [width] = useDimension();

  const isTablet = width < 1200;
  const isMobile = width < 650;

  const { data }: { data: any } = countryStateCity.useCountriesFilters({ only_published: true });
  const optionsList = formatRegions(data || []).filter((item: any) => item.label);

  function setNodes(node: any) {
    setNodesToShow(nodesToShow.concat(node));
  }

  function goBack(nodeToRemove: any) {
    setNodesToShow(nodesToShow.filter(node => !areEqual(nodeToRemove, node)));
  }

  const unselectRegions = (selectedLocations: any) => unselectLocationsByChildren(selectedLocations, "region", "city");
  const unselectCountries = (selectedLocations: any) => unselectLocationsByChildren(selectedLocations, "country", "region");
  const selectRegions = (selectedLocations: any) => selectLocationsByChildren(optionsList, selectedLocations, "region", "city");
  const selectCountries = (selectedLocations: any) => selectLocationsByChildren(optionsList, selectedLocations, "country", "region");

  const removeListElement = (node: any) => () => {
    const selectedElements = flattenChildrenRecursive(node);
    const selectedElementsIds = selectedElements.map((el: any) => ({ value: el.value, type: el.type }));
    let newSelected: any = (selected || [])
      .filter((el: any) => !selectedElementsIds
        .find((selectedVal: any) => selectedVal.value === el.value && selectedVal.type === el.type));
    
    newSelected = uniqBy(selectCountries(
      selectRegions(
        unselectCountries(
          unselectRegions(newSelected),
        ),
      ),
    ), function(elem) { return [elem.type, elem.label].join(); });

    setSelected(newSelected);
    onChange && onChange(newSelected.filter((item: any) => item.type === "city").map((item: any) => item.value));
  };

  useEffect(() => {
    if (value && optionsList.length) {
      setSelected(flattenLocationArray(value));
    }
  }, [JSON.stringify(value), JSON.stringify(optionsList)]);

  useEffect(() => {
    setMaxHeight({ current: getDefaultHeight(data), next: null });
  }, [JSON.stringify(data)]);

  useEffect(() => {
    if (isMobile && multiRef.current) {
      setMaxHeight({ ...maxHeight, current: multiRef.current.getBoundingClientRect().height });
    }
    if (!nodesToShow.length) {
      setMaxHeight({ next: null, current: getDefaultHeight(data) });
    }
  }, [nodesToShow, filterVal, JSON.stringify(data)]);

  const handleSelect = useCallback((node: any) => {
    const selectedElements = flattenChildrenRecursive(node);
    const selectedElementsIds = selectedElements.map((el: any) => ({ value: el.value, type: el.type }));

    let newSelected = [];

    if (!node.checked) {

      newSelected = (selected || []).filter((el: any) => !selectedElementsIds.find((selectedVal: any) => selectedVal.value === el.value &&
          selectedVal.type === el.type));
    } else {
      newSelected = [
        ...(selected || []).filter((el: any) => !selectedElementsIds.find((selectedVal: any) => selectedVal.value === el.value &&
            selectedVal.type === el.type)),
        ...selectedElements.map((el: any) => ({ ...el, checked: true }))
      ];
    }

    newSelected = uniqBy(selectCountries(
      selectRegions(
        unselectCountries(
          unselectRegions(newSelected),
        ),
      ),
    ), (elem) => `${elem.type}-${elem.label}-${elem.value}`);

    setSelected(newSelected as any);
    onChange && onChange(newSelected.filter(item => item.type === "city").map(item => item.value));
  }, [selected, optionsList, nodesToShow, filterVal]);

  const handleHeightCb = useCallback((contentBlockHeight: any) => {
    setMaxHeight(prev => {
      if (nodesToShow.length > 1 && contentBlockHeight < prev.current && prev.current > getDefaultHeight(data)) {
        return { ...prev, current: prev.next };

      }
      if (contentBlockHeight > prev.current) {
        if (!nodesToShow.length) {
          return { current: contentBlockHeight, next: contentBlockHeight };
        }

        return { current: contentBlockHeight, next: prev.current };
      }

      return prev;
    });
  }, [maxHeight, selected, nodesToShow]);

  const generateLabels = (selectedNodes: any) => {
    if (!selectedNodes || !selectedNodes.length) {
      return null;
    }

    return (
      <div className={styles["locl-where-select__labels-list"]} data-testid="locl-where-select-labels-list">
        {
          removeChildrenFromFlattenLocations(selectedNodes).map((item, index) => (
            <Tag
              closable
              icon={null}
              key={`${index}-${item.label}`}
              onClose={removeListElement(item)}
              className={styles["locl-where-select__labels-list__item"]}
              data-testid="locl-where-select-labels-list-item"
            >
              {item.label}
            </Tag>
          ))
        }
      </div>
    );
  };

  const renderNode = (d: any, isStartNode: any, key: any) => {
    return <WhereSelectItem
      key={key}
      selected={selected}
      data={d}
      handleSelect={handleSelect}
      setNodes={setNodes}
      goBack={goBack}
      isStartNode={isStartNode}
      isMobile={isMobile}
      handleHeightCb={handleHeightCb}
    />;
  };

  const renderMobile = () => {
    const flattedData = flattenLocationArray(optionsList);
    const filteredData = flattedData.filter((el: any) => el.label.toLowerCase().includes(filterVal.toLowerCase()));
    const renderData = filteredData.length < 1 || !filterVal ? [...optionsList] : [...filteredData];

    const mobileSelectedNode = nodesToShow[nodesToShow.length - 1] as any;
    const selectedNodeChildren = [...(mobileSelectedNode?.children || [])];
    const greaterLondonIndex = selectedNodeChildren.findIndex(el => el.label === "Greater London");

    if (greaterLondonIndex !== -1) {
      const greaterLondon = selectedNodeChildren[greaterLondonIndex];
      selectedNodeChildren.splice(greaterLondonIndex, 1);
      selectedNodeChildren.unshift(greaterLondon);
    }

    return (
      <div className={styles["locl-where-select__dropdown-tree"]} data-testid="locl-where-select-dropdown-tree-mobile" ref={multiRef}>
        {nodesToShow.length
          ? (
            <div className={styles["locl-where-select__dropdown-tree-mobile-wrapper"]}>
              <div className={styles["locl-where-select__dropdown-tree-mobile-wrapper-header"]}>
                <div
                  className={styles["locl-where-select__dropdown-go-back"]}
                  data-testid="locl-where-select-dropdown-go-back"
                  onClick={() => goBack(nodesToShow[nodesToShow.length - 1])}
                >
                  <FaChevronLeft className={styles["locl-where-select__dropdown-go-back-icon"]}/>
                  <span className={styles["locl-where-select__dropdown-go-back-label"]}>Back</span>
                </div>
                <WhereSelectItem
                  selected={selected}
                  data={mobileSelectedNode}
                  handleSelect={handleSelect}
                  handleHeightCb={() => null}
                  setNodes={setNodes}
                  isStartNode={true}
                  goBack={goBack}
                  isDisabled={true}
                />
              </div>
              <div className={styles["locl-where-select__dropdown-menu-mobile"]}>
                {selectedNodeChildren.map((d, i) => renderNode((d), true, `${optionsList.length} ${i.toString()}`))}
              </div>
            </div>
          )
          : renderData.map((d, i) => renderNode((d), true, optionsList.length + i.toString())) }
      </div>
    );

  };

  const dataToRender = () => {
    const flattedData = flattenLocationArray(optionsList);
    const filteredData = flattedData.filter((el: any) => el.label.toLowerCase().includes(filterVal.toLowerCase()));
    const renderData = filteredData.length < 1 || !filterVal ? [...optionsList] : [...filteredData];

    return (
      <div className={styles["locl-where-select__dropdown-tree"]} data-testid="locl-where-select-dropdown-tree-desk">
        {renderData.map((d, i) => renderNode((d), true, optionsList.length + i.toString()))}
      </div>
    );

  };

  return (
    <div className={`locl-where-select ${styles["locl-where-select"]} ${overlayClassName}`} data-testid="locl-where-select">
      <div className={styles["locl-where-select__search-container"]} ref={inputRef}>
        {generateLabels(selected)}
        <div className={styles["locl-where-select__search-inner"]}>
          <input
            onChange={e => setFilterVal(e.target.value)}
            placeholder="Search"
            className={styles["locl-where-select__search-input"]}
            data-testid="locl-where-select-search-input"
            value={filterVal}
          />
          {!!filterVal && (
            <span className={styles["locl-where-select__clear"]} data-testid="locl-where-select-clear" onClick={() => setFilterVal("")}>
              <IoClose/>
            </span>
          )
          }
        </div>
      </div>
      <AnimateHeight className={styles["locl-where-select__dropdown-area"]} duration={50} height={maxHeight.current + 8}>
        {isTablet || isMobile ? renderMobile() : dataToRender()}
      </AnimateHeight>
    </div>
  );

};
export default WhereSelect;
