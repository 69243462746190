// helpers
import purposes from "@/api/query/purposes";
import countryStateCity from "@/api/query/countryStateCity";
import { getSelectedValues, formatRegions, TreeNode } from "@/components/WhereSelect/helpers";
import { getNestedWhereSelectorValues } from "@/components/SearchComponents/helpers";
import { IAM_FLEXIBLE_OPTION, SPACE_TYPE_OPTIONS, SPACE_TYPES } from "@/globals/global.constants";


export interface UseSearchFiltersDataInterface {
  city__in: number[];
  type__in?: number[];
  purpose__in?: number | null;
}

const useSearchFiltersData = ({ city__in, type__in, purpose__in }: UseSearchFiltersDataInterface) => {
  const { data: purposesResp } = purposes.usePurposesList();
  const { data: countriesResp } = countryStateCity.useCountriesFilters({ only_published: true });

  const cityOptionsList = formatRegions(countriesResp || []).filter(item => item.label) as TreeNode[];
  const selectedCity = getNestedWhereSelectorValues(cityOptionsList, city__in);
  const selectedCityOptions = getSelectedValues(countriesResp, city__in);

  const purposesList = (purposesResp || []).map(item => ({ value: item.id, label: item.name }));
  const selectedPurpose = purposesList.find(item => item.value === purpose__in);

  if (selectedPurpose?.label) {
    sessionStorage.setItem("lastSelectedPurpose", `${selectedPurpose.value}`);
  }

  const selectedTypeIn = (!type__in || !type__in.length)
    ? [IAM_FLEXIBLE_OPTION]
    : [
      ...SPACE_TYPE_OPTIONS.filter(item => type__in.some(spaceType => `${spaceType}` === `${item.value}`)),
      type__in.length === SPACE_TYPES.length && IAM_FLEXIBLE_OPTION,
    ].filter(Boolean);


  return {
    purposes: purposesList,
    whereOptions: cityOptionsList,
    selectedCityOptions,
    selectedCity,
    selectedTypeIn,
    selectedPurpose
  };
};

export default useSearchFiltersData;
