import React, { useRef, MouseEvent, useState, useEffect, ChangeEvent } from "react";
import { FaArrowRightLong } from "react-icons/fa6";

// components
import Input from "@/shared/Input";

// helpers
import useClickOutside from "@/hooks/useClickOutside";
import dayjs from "@/helpers/dayjs";

// assets
import styles from "@/assets/styles/components/when-dropdown-picker.module.scss";


const DATE_FORMATS = [
  "YYYY-MM-DD",
  "YYYY/MM/DD",
  "YYYY.MM.DD",
  "DD-MM-YYYY",
  "DD/MM/YYYY",
  "DD.MM.YYYY",
  "MM-DD-YYYY",
  "MM/DD/YYYY",
  "MM.DD.YYYY",
  "D-M-YYYY",
  "D/M/YYYY",
  "D.M.YYYY",
  "M-D-YYYY",
  "M/D/YYYY",
  "M.D.YYYY",
  "YYYY-M-D",
  "YYYY/M/D",
  "YYYY.M.D",
  "MMMM D, YYYY",
  "D MMMM, YYYY",
  "MMMM Do, YYYY",
  "Do MMMM, YYYY",
  "DD MMM YYYY",
  "DD MMMM YYYY",
];

function parseDate(input: string) {
  for (const format of DATE_FORMATS) {

    const date = dayjs(input, format);

    if (date.isValid()) {
      return date;
    }
  }

  return null;
}

export interface WhenDropdownPickerInputProps {
  value?: [dayjs.Dayjs | null, dayjs.Dayjs | null];
  onChange: (value: [dayjs.Dayjs | null, dayjs.Dayjs | null]) => void;
}

function WhenDropdownPickerInput({ value = [null, null], onChange }: WhenDropdownPickerInputProps) {
  const [dateValue, setDateValue] = useState(value)
  
  const startDateRef = useRef<HTMLInputElement | null>(null);
  const endDateRef = useRef<HTMLInputElement | null>(null);

  useClickOutside(startDateRef, () => startDateRef.current?.blur());
  useClickOutside(endDateRef, () => endDateRef.current?.blur());

  // methods
  const handleInputFocus = (e: MouseEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    input.focus();

    if (input.name === "start") {
      startDateRef.current = input;

      return;
    }

    endDateRef.current = input;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    const date = parseDate(input.value);

    if (input.name === "start") {
      setDateValue([date, dateValue[1]]);
      date && onChange([date, dateValue[1]]);

      return;
    }

    setDateValue([dateValue[0], date]);
    date && onChange([dateValue[0], date]);
  };

  // effects
  useEffect(() => {
    value && setDateValue(value);
  }, [value]);

  // renders
  return (
    <div className={styles["locl-when-dropdown-picker__popup__panel-header"]}>
      <Input
        placeholder="dd/mm/yy"
        label="Start date"
        name="start"
        value={dateValue[0]?.format("DD/MM/YYYY")}
        onClick={handleInputFocus}
        onChange={handleChange}
      />
      <FaArrowRightLong/>
      <Input
        placeholder="dd/mm/yy"
        label="End date"
        name="end"
        value={dateValue[1]?.format("DD/MM/YYYY")}
        onClick={handleInputFocus}
        onChange={handleChange}
      />
    </div>
  );
}

export default WhenDropdownPickerInput;
