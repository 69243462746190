import { useEffect, RefObject } from "react";

function useClickOutside(
  node: RefObject<HTMLElement>,
  callback: () => void,
  ignoreNode: RefObject<HTMLElement>[] = [],
  argsToListen: unknown[] = []
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        ignoreNode.some(
          (item) => item && item.current && item.current.contains(event.target as Node)
        )
      ) {
        return;
      }

      if (node.current && !node.current.contains(event.target as Node)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [node, argsToListen]);
}

export default useClickOutside;
