import dayjs, { Dayjs } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import localeData from "dayjs/plugin/localeData";
import minMax from "dayjs/plugin/minMax";
import isBetween from "dayjs/plugin/isBetween";
import isToday from "dayjs/plugin/isToday";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import customParseFormat from "dayjs/plugin/customParseFormat";
import weekday from "dayjs/plugin/weekday";
import "dayjs/locale/en-gb";

dayjs.locale("en-gb");
dayjs.extend(advancedFormat);
dayjs.extend(localeData);
dayjs.extend(minMax);
dayjs.extend(isBetween);
dayjs.extend(isToday);
dayjs.extend(quarterOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(weekday);


export { Dayjs };
export default dayjs;
