// lang
export const LOCATION_TO_HTML_LANGS = {
  uk: "en",
  aus: "en",
}

// date
export const WEEK_DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
export const WEEK_DAYS_TWO_COLUMNS = ["Monday", "Friday", "Tuesday", "Saturday", "Wednesday", "Sunday", "Thursday"];
export const WEEK_DAYS_SHORT = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

// spaces
export const SPACE_TYPES = [
  { value: 0, label: "Internal" },
  { value: 1, label: "External" },
];

export const IAM_FLEXIBLE_KEY = "flexible";
export const IAM_FLEXIBLE_OPTION = { value: IAM_FLEXIBLE_KEY, label: "I'm flexible" };
export const SPACE_TYPE_OPTIONS = [...SPACE_TYPES, IAM_FLEXIBLE_OPTION];


export const ACTIVITY_BOOKED_STATUSES = ["BOOKED", "BOOKING_SUBMITTED", "BOOKED_BY_ME", "PENDING"];
export const MY_ACTIVITY_UNAVAILABLE_STATUSES = ["BOOKING_SUBMITTED", "BOOKED_BY_ME", "PENDING"];

export const ACTIVITY_OPTION_STATUSES = ["UNDER_OPTION", "OPTION_SUBMITTED", "OPTION_BY_ME"];
export const MY_ACTIVITY_OPTION_STATUSES = ["OPTION_SUBMITTED", "OPTION_BY_ME"];
