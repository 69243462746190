import { IUseQueryResult } from "@/types/api/UseQueryResult";

// helpers
import { useFetch } from "./reactQuery";
import API_ROUTES from "@/api/routes.constant";

// interfaces
import { QueryArgsInterface } from "@/types/api/queryArgs";
import { IWhenSelectorDates } from "@/types/api/WhenSelectorDates";
import { IPaginatedResponse } from "@/types/api/PaginatedResponse";

const { calendarUrls } = API_ROUTES;

const calendar = {
  useWhenSelectorDates: (args?: QueryArgsInterface) => {
    const { params, config } = args || {};

    return useFetch(calendarUrls.whenSelectorDates, params, config) as
    IUseQueryResult<IPaginatedResponse<IWhenSelectorDates>>;
  },
};

export default calendar;
