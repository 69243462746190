import { IUseQueryResult } from "@/types/api/UseQueryResult";

// helpers
import { useFetch } from "./reactQuery";
import { pathToUrl } from "./reactQuery/pathToUrl";
import API_URLS from "@/api/routes.constant";

// interfaces
import { QueryArgsInterface } from "@/types/api/queryArgs";
import { IAbstractRespListItem } from "@/types/Global";

const { purposesUrls } = API_URLS;

const purposes = {
  usePurposesList: (args?: QueryArgsInterface) => {
    const { params, config } = args || {};

    return useFetch(purposesUrls.purposes, params, config) as IUseQueryResult<IAbstractRespListItem[]>;
  },

  usePurpose: (id: number) => useFetch(id ? pathToUrl(purposesUrls.purposeSingle, { id }) : null),
};

export default purposes;
