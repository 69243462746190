/* eslint-disable indent */
import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { Dropdown as AntdDropdown } from "antd";
import get from "lodash/get";

// interfaces
import { DropdownProps, DropdownMenuItemProps } from "./IDropdown";

// elements
import DropdownButton from "./DropdownButton";
import Checkbox from "@/shared/Checkbox";

// assets
import styles from "../../assets/styles/shared/dropdown/index.module.scss";

type Ref = React.Ref<{
  close: () => void;
  open: () => void;
}>;

const Dropdown = (props: DropdownProps, ref: Ref) => {
  const {
    menu,
    onChange,
    value = null,
    isMulti,
    placeholder,
    overlayClassName = "",
    onOpenChange,
    icon,
    children,
    disabled,
    className = "",
    buttonProps = {},
    ...rest
  } = props;

  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<
    DropdownMenuItemProps | DropdownMenuItemProps[]
  >([]);

  useImperativeHandle(ref, () => ({
    close: () => setOpen(false),
    open: () => setOpen(true)
  }));

  useEffect(() => {
    if (typeof value !== "undefined") {
      setSelectedValue(value as DropdownMenuItemProps | DropdownMenuItemProps[]);
    }
  }, [JSON.stringify(value)]);

  const handleItemClick = ({ key }: { key: string }) => {
    const selectedItem = (get(menu, "items", []).find((item) => `${item?.value}` === `${key}`)) as DropdownMenuItemProps;

    if (isMulti && (!selectedValue || !get(selectedValue, "length"))) {
      onChange && onChange([selectedItem], key);
      setSelectedValue([selectedItem as DropdownMenuItemProps]);

      return;
    } else if (isMulti) {
      const valuesToSet = (selectedValue as DropdownMenuItemProps[]).some(
        (item) => `${item.value}` === `${key}`
      )
        ? (selectedValue as DropdownMenuItemProps[]).filter((item: DropdownMenuItemProps) => `${item.value}` !== `${key}`)
        : [...(selectedValue as DropdownMenuItemProps[]), selectedItem];

      onChange && onChange(valuesToSet, key);
      setSelectedValue(valuesToSet as DropdownMenuItemProps[]);

      return;
    }

    if (
      selectedItem &&
      (typeof selectedItem.isClosable === "undefined" || selectedItem.isClosable)
    ) {
      setOpen(false);
      onOpenChange && onOpenChange(false);
    }

    setSelectedValue(selectedItem as DropdownMenuItemProps);
    onChange && onChange(selectedItem, key);
  };

  const renderLabel = (item: DropdownMenuItemProps) => {
    if (isMulti) {
      const isChecked = ((selectedValue as DropdownMenuItemProps[]) || []).some(
        (e: DropdownMenuItemProps) => `${e.value}` === `${item.value}`
      );

      return (
        <div className={styles["locl-dropdown-options__checkbox-item"]}>
          <Checkbox size="small" checked={isChecked} onClick={(e) => e.preventDefault()} />
          {item.label}
        </div>
      );
    }

    return item.label;
  };

  const renderValue = () => {
    if (Array.isArray(selectedValue)) {
      return selectedValue.map((item) => item.label).join(", ");
    }

    return selectedValue ? selectedValue.label : null;
  };

  const handleOpenChange = (e: boolean, info: { source: "menu" | "trigger"; }) => {
    if (disabled || info.source !== "trigger") {
      return;
    }

    setOpen(e);
    onOpenChange && onOpenChange(e);
  };

  return (
    <AntdDropdown
      menu={{
        items: get(menu, "items", []).map((item) => ({
          label: renderLabel(item),
          key: item.value as string
        })),
        selectedKeys: [],
        onClick: handleItemClick
      }}
      trigger={["click"]}
      // overlayClassName={styles[`locl-dropdown-options ${overlayClassName}`]}
      // TODO: fix overlayClassName to overlayClassName={classNames(styles[`locl-dropdown-options`], overlayClassName)}
      // and check all places whether the component is displayed correctly
      overlayClassName={overlayClassName}
      onOpenChange={handleOpenChange}
      open={open}
      placement="bottomRight"
      {...rest}
    >
      {children
        ? (
          <div
            onClick={(e) => e.preventDefault()}
            className={disabled ? styles["locl-dropdown__inner_disabled"] : ""}
          >
            { children }
          </div>
        )
        : (
        <div
          className={styles[`locl-dropdown__inner ${className}`]}
          // TODO: fix className to className={classNames(styles[`locl-dropdown__inner`], className)}
          // and check all places whether the component is displayed correctly
          onClick={(e) => e.preventDefault()}
        >
          <DropdownButton
            disabled={disabled}
            placeholder={placeholder}
            value={renderValue() as string}
            icon={icon}
            {...buttonProps}
          />
        </div>
      )}
    </AntdDropdown>
  );
};

Dropdown.displayName = "Dropdown";

export default forwardRef(Dropdown) as typeof Dropdown;
